import React, { Component } from "react";
import { Link } from "react-router-dom";

class PrivacyPolicyPage extends Component {
    render() {
        return (
            <div className="privacypolicymaindiv">
                <div className="privacy-policy-page">

                    <h1 className="welcometitle">Privacy Policy</h1>
                    <p className="privacy-policy-text">
                        This policy describes the personally identifiable information and other data we will collect from our applications, and how we might use and display such data to third parties. Your Installation, Use, and Submission of Information to us establish your consent to this Privacy Policy and the processing of your information as described in this policy. Please use the services only if you agree to this Privacy Policy.
                        <br />
                        Please Note: If you or Your Legal Guardian disagree with any term provided herein, you may not install or use the apps and are requested to uninstall the app from your device promptly.
                    </p>
                    <h2 className="title">How We Use Your Personal Information</h2>
                    <p className="privacy-policy-text">
                        All user data including media files (audio, video, images) and documents (including contact lists) are securely handled by Our Apps by employing encryption. Moreover, the files that the user backs up on our servers are completely private and are in no way publicly visible or accessible. As per practice in vogue, the app’s personnel may need to access file contents in rare scenarios like
                    </p>
                    <h2 className="title">Legal obligation</h2>
                    <p className="privacy-policy-text">
                        Ensuring satisfactory user experience (e.g. debugging, performance testing, search result verification, recommendations, etc.)Enforcing our Terms of Service and Acceptable Use Policy.
                        <br />
                        Such file access is limited to a minimal number of people. We ensure the implementation of strict policy and access controls.
                    </p>
                    <h2 className="title">Sharing of User Information</h2>
                    <p className="privacy-policy-text">
                        Any information collected by Our Apps is only for improving the user experience. All information collected is encrypted through industry-standard mechanisms. Our Apps employ best practices to safeguard user information from unauthorized visibility/ access. In certain cases, Our Apps may disclose user information to 3rd parties for advertising purposes. Our Apps use advertising campaigns so as not to reveal the user's personal information while providing a tailored advertisement to suit user requirements. In case of legal action, Our Apps may be obliged to disclose user information to third parties against some malicious acts on the part of the user. Our App advises its users to take necessary precautions when sharing information with unknown 3rd parties.
                    </p>
                    <h2 className="title">Link to Third-Party Websites and Services</h2>
                    <p className="privacy-policy-text">
                        The APPS may contain links to other websites and online services, including third-party advertisements. If you choose to click through one of these other websites or online services, please note that any information you may provide will be subject to that website’s or service’s privacy policy and their terms & conditions, and not to this Privacy Policy. We do not share your data with third-party websites or services, with the fact that a link to such websites or services does not appear in our APPS. You agree that our application will have no liability for any matters related to the third-party website or service you provide information to, including their collection and handling.
                    </p>
                    <h2 className="title">Adherence with Laws</h2>
                    <p className="privacy-policy-text">
                        ("We") reserve the right to share your information with third parties if we believe such action is necessary to:
                        <br />
                        Conform to the requirements of the law or comply with the legal process served upon us.
                        <br />
                        Protect or defend our legal rights or property.
                        <br />
                        Investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or violations of the terms and conditions of using our Apps and Services.
                    </p>

                    <h2 className="title">Permissions Our Apps May Ask For</h2>
                    <p className="privacy-policy-text">
                        Our apps may ask for the following permissions but that will be for the sole purpose of providing core functionality. There will be no invasion of privacy or unethical use of resources or data.
                        <ul>
                            <li>Camera</li>
                            <li>Contacts</li>
                            <li>Location</li>
                            <li>Music & Audio</li>
                            <li>Nearby Devices</li>
                            <li>Notifications</li>
                            <li>Photos & Videos</li>
                            <li>Record Audios</li>
                            <li>Manage All Files</li>
                        </ul>
                    </p>

                    <h2 className="title">Data Security</h2>
                    <p className="privacy-policy-text">
                        AUZI APPS STUDIOS has implemented technology and policies to safeguard your privacy from unauthorized access and improper use.
                    </p>

                    <h2 className="title">
                        Changes to our Privacy Policy
                    </h2>
                    <p className="privacy-policy-text">
                        This Privacy Policy may be updated for any reason e.g. legal, economic, public opinion, etc. We reserve the right to update the policy and have no obligation to inform users actively. Users are welcome to revisit the page for updates to the policy.
                    </p>

                    <h2 className="title">Children's Privacy</h2>
                    <p className="privacy-policy-text">
                        We don’t perceptively gather personally identifiable information from anyone under the age of 13. If you are a parent or guardian be aware that children have provided us with personal data please let us know. We are obliged to remove any information from our services if we come to know that data is gathered from children without the acquiescence of the parent.
                    </p>
                    <h2 className="title">
                        Contact us
                    </h2>
                    <p className="privacy-policy-text">
                        If you have any questions regarding privacy while using the apps, or have questions about our practices, please contact us via email at
                        auziappsstudios@gmail.com
                    </p>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicyPage;
